<template>
  <Page :title="pageTitle" id="project">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="is-flex" v-if="project">
      <div class="tabs" v-if="viewBidPackageId !== null">
        <Tabs :bid-package-id="viewBidPackageId" @projectBidderUpdated="loadBidPackageProjectBidders" />
      </div>
      <div v-else>
        <BidPackagesList layout-type="landing" />
      </div>
    </div>

    <o-modal v-model:active="isConfirmLeaveBidding" :width="600">
      <div class="title">Will You Be Bidding?</div>
      <div>You are leaving without letting us know if you are bidding or not.</div>
      <div>Will you be bidding?</div>

      <div class="has-text-right">
        <o-button class="is-danger has-margin-right-large" @click="handleBidding('no')">No</o-button>
        <o-button class="is-success" @click="handleBidding('yes')">Yes</o-button>
      </div>
    </o-modal>
 </Page>
</template>

<script setup lang="ts">

import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import { storeToRefs } from 'pinia';
import {DateTime} from "luxon";

import Page from "@/components/Page.vue";
import Tabs from "./Tabs.vue";

import {
  NotificationError,
  NotificationSuccess,
} from "@/internal";
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import BidPackagesList from "@/pages/Projects/BidPackagesList.vue";
import type {BidPackage} from "@/models/BidPackage";
import {useBidPackageProjectBidderStore} from "@/stores/BidPackageProjectBidderStore";
import {useProjectStore} from "@/stores/ProjectStore";

const bidPackageProjectBidderStore = useBidPackageProjectBidderStore();
const projectStore = useProjectStore();
const route = useRoute();
const router = useRouter();
const { project, viewBidPackageId } = storeToRefs(useProjectStore());
const goToRoute = ref<any | null>(null);
const isConfirmLeaveBidding = ref<boolean>(false);
const interval = ref<number | null>(null);

const biddingNotAnswered = computed(() => {
  return (isOpenProjectAndUninvited.value && !project.value?.bidders.length && !project.value?.bid_packages.length)
      || (project.value?.bidders.length && project.value?.is_bidding === 0)
      || (project.value?.bid_packages.length && project.value?.bid_packages.filter((bidPackage: BidPackage) => bidPackage.is_bidding === 0).length);
});

const isLoading = computed(() => projectStore.isLoading || bidPackageProjectBidderStore.isLoading);

const isOpenProjectAndUninvited = computed(() => project.value?.open_invitation && !project.value.invited);

const pageTitle = computed(() => {
  return project.value ? project.value.name : '';
});

const projectId = computed(() => parseInt(route.params.projectId as string));

onMounted(() => {
  loadProject();
});

onBeforeRouteLeave(async (to, from) => {
  if (goToRoute.value) {
    return true;
  }

  goToRoute.value = to;
  if (biddingNotAnswered.value && project.value?.sub_bid_due && project.value?.sub_bid_due > DateTime.local().toFormat('yyyy-LL-dd ttt')) {
    isConfirmLeaveBidding.value = true;

    interval.value = setInterval((to: any) => {
      if (!biddingNotAnswered.value || !isConfirmLeaveBidding.value) {
        if (interval.value) {
          clearInterval(interval.value);
          interval.value = null;
        }
        router.push(to);
      }
    }, 1000, to);

    return false;
  }
});

onBeforeUnmount(() => {
  projectStore.setViewBidPackageId(null);
  bidPackageProjectBidderStore.clearBidder();
  bidPackageProjectBidderStore.clearBidders();
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
});

function handleBidding(answer: 'yes' | 'no') {
  if (!['yes', 'no'].includes(answer)) {
    return;
  }

  const payload: any = {
    confirmed: answer === 'yes' ? 1 : 2,
  };

  bidPackageProjectBidderStore.updateUnanswered(<number>project.value?.id, payload)
    .then(() => {
      NotificationSuccess({
        message: `Response Updated`,
      });

      handleProjectBidderUpdated();
    }).catch((error: any) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      isConfirmLeaveBidding.value = false;
    });
}

function handleProjectBidderUpdated() {
  loadProject();
  loadBidPackageProjectBidders();
}

function loadBidPackageProjectBidders() {
  if ((viewBidPackageId.value === null) || !projectId.value) {
    return;
  }

  const payload = {
    params: {
      include: [
          'trade',
      ],
    }
  };

  bidPackageProjectBidderStore.index(projectId.value, viewBidPackageId.value, payload)
    .catch(error => {
      if (error.errors) {
        NotificationError({}, error);
      } else {
        NotificationError({
          message: error,
        });
      }
    });
}

function loadProject() {
  const payload = {
    params: {
      include: [
        'bidders.trade',
        'bidPackages',
        'estimators',
        'notices',
        'projectContacts',
      ],
    }
  };

  projectStore.show(projectId.value, payload)
      .then(() => {
        if (project.value?.open_invitation && !project.value?.invited) {
          projectStore.setViewBidPackageId(0);
        }
      }).catch(error => {
        if (error.errors) {
          NotificationError({}, error);
        } else {
          NotificationError({
            message: error,
          });
        }
      });

}

watch(viewBidPackageId, () => {
  loadBidPackageProjectBidders();
});

watch(isConfirmLeaveBidding, () => {
  if (!isConfirmLeaveBidding.value && biddingNotAnswered.value && interval.value) {
    clearInterval(interval.value);
    interval.value = null;
    router.push(goToRoute.value);
  }
});

</script>

<style lang="scss" scoped>

#project {

  .bid-packages {
    width: 250px;

    .bid-packages-list {
      height: 78vh;
      overflow-y: scroll;
      margin-top: 16px;
    }
  }

  .tabs {
    padding-top: 0px;
    margin-left: -1px;
    width: 100%;
  }

}

</style>

<style lang="scss">

#project {

  .b-tabs {
    .tabs {
      width: 100%;
      white-space: normal;
    }
  }
}

.b-tabs {
  a {
    color: $link !important;

    &.is-active {
      color: $text !important;
      font-weight: 500;
    }
  }
}

</style>