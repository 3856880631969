import {defineStore} from "pinia";
import { StorageSerializers, useStorage } from '@vueuse/core';
import {ref} from "vue";
import type {ObjectWithMeta} from "@/api/Api";
import BidPackageProjectBidderAPI from "@/api/BidPackageProjectBidderAPI";
import type {ProjectBidder} from "@/models/ProjectBidder";

export const useBidPackageProjectBidderStore = defineStore('bidPackageProjectBidders', () => {

  const bidPackageProjectBidder = useStorage<ProjectBidder | undefined>('bidPackageProjectBidder', null, sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const bidPackageProjectBidders = useStorage('bidPackageProjectBidders', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const bidPackageProjectBiddersLinks = useStorage('bidPackageProjectBidders.links', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const bidPackageProjectBiddersMeta = useStorage('bidPackageProjectBidders.meta', [], sessionStorage, {
    serializer: StorageSerializers.object,
  });
  const isLoading = ref<boolean>(false);

  const clearBidder = () => {
    bidPackageProjectBidder.value = null;
  };

  const clearBidders = () => {
    bidPackageProjectBidders.value = null;
    bidPackageProjectBiddersLinks.value = null;
    bidPackageProjectBiddersMeta.value = null;
  };

  const index = async (projectId: number, bidPackageId: number, payload: any) => {
    const { params } = payload;

    isLoading.value = true;
    clearBidders();
    await new BidPackageProjectBidderAPI().index(projectId, bidPackageId, params)
        .then((data: ObjectWithMeta<ProjectBidder>) => {
          bidPackageProjectBidders.value = data.data;
          bidPackageProjectBiddersLinks.value = data.links;
          bidPackageProjectBiddersMeta.value = data.meta;
        })
        .finally(() => isLoading.value = false);
  }

  const show = async (id: number, projectId: number, bidPackageId: number, payload: any = {params: null}) => {
    isLoading.value = true;
    clearBidder();
    const { params } = payload;
    await new BidPackageProjectBidderAPI().show(id, projectId, bidPackageId, params)
        .then(data => {
          bidPackageProjectBidder.value = data;
        })
        .finally(() => isLoading.value = false);
  }

  const store = async (projectId: number, bidPackageId: number, payload: any = {params: null}) => {
    isLoading.value = true;
    const { params, ...rest } = payload;
    await new BidPackageProjectBidderAPI().store(projectId, bidPackageId, rest)
        .then(data => {
          return data;
        })
        .finally(() => isLoading.value = false);
  }

  const update = async (id: number, projectId: number, bidPackageId: number, payload: any = {params: null}) => {
    isLoading.value = true;
    const { params, ...rest } = payload;
    await new BidPackageProjectBidderAPI().update(id, projectId, bidPackageId, rest)
        .then(data => {
          return data;
        })
        .finally(() => isLoading.value = false);
  }

  const updateUnanswered = async (projectId: number, payload: any = {params: null}) => {
    isLoading.value = true;
    const { params, ...rest } = payload;
    await new BidPackageProjectBidderAPI().updateUnanswered(projectId, rest)
        .then(data => {
          return data;
        })
        .finally(() => isLoading.value = false);
  }

  return {
    bidPackageProjectBidder,
    bidPackageProjectBidders,
    bidPackageProjectBiddersLinks,
    bidPackageProjectBiddersMeta,
    isLoading,
    index,
    clearBidder,
    clearBidders,
    show,
    store,
    update,
    updateUnanswered,
  };
});
