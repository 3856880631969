import type {APIResponse, ObjectWithMeta} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {Project} from '@/models/Project';
import {ProjectSchema} from "@/models/Project";
import formatRFQDateTime from "@/use/formatRFQDateTime";

export default class ProjectAPI extends Api {

  index(params: any): Promise<ObjectWithMeta<Project>> {
    return this.get({
      url: 'projects',
      queryParams: params
    })
    .then((res) => this.processResponse(res))
    .then(res => {
      res.data = res?.data?.map((project: any) => {
        project.attributes.sub_bid_due = formatRFQDateTime(project.attributes.sub_bid_date, project.attributes.sub_bid_time);
        project.attributes.site_meeting_at = formatRFQDateTime(project.attributes.site_meeting_date, project.attributes.site_meeting_time);
        return project;
      });
      return {
        links: res.links,
        meta: res.meta,
        data: deserialize<Project>(res, ProjectSchema) as Project[],
      };
    });
  }

  show(id: number, params: any): Promise<Project> {
    return this.get({
      url: `projects/${id}`,
      queryParams: params
    })
    .then((res) => this.processResponse(res))
    .then((res) => {
      res.data.attributes.sub_bid_due = formatRFQDateTime(res.data.attributes.sub_bid_date, res.data.attributes.sub_bid_time);
      res.data.attributes.site_meeting_at = formatRFQDateTime(res.data.attributes.site_meeting_date, res.data.attributes.site_meeting_time);
      res.included = res.included?.map((obj: any) => {
        if (obj.type === "bidPackage") {
          return {
            ...obj,
            attributes: {
              ...obj.attributes,
              sub_bid_due: formatRFQDateTime(obj.attributes.sub_bid_date, obj.attributes.sub_bid_time)
            },
          }
        }
        else {
          return obj;
        }
      });
      return res;
    })
    .then(res => deserialize<Project>(res, ProjectSchema) as Project);
  }

  showPublic(id: number, params: any): Promise<Project> {
    return this.get({
      url: `projects/${id}/public`,
      queryParams: params
    })
    .then((res) => this.processResponse(res))
    .then((res) => {
      res.data.attributes.sub_bid_due = formatRFQDateTime(res.data.attributes.sub_bid_date, res.data.attributes.sub_bid_time);
      res.data.attributes.site_meeting_at = formatRFQDateTime(res.data.attributes.site_meeting_date, res.data.attributes.site_meeting_time);
      return res;
    })
    .then(res => deserialize<Project>(res, ProjectSchema) as Project);
  }
};
