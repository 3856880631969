<template>
  <div id="tabs">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <o-tabs
        animated
        type="boxed"
        v-model="activeTab"
    >
      <o-tab-item :label="biddingTabLabel" value="bidding" item-header-active-class="item-header-active-class">
        <Bidding :bid-package-id="bidPackageId" @projectBidderUpdated="handleProjectBidderUpdated" :public-project-view="publicProjectView" />
      </o-tab-item>
      <o-tab-item label="Info" value="info">
        <Info :bid-package-id="bidPackageId" :public-project-view="publicProjectView" />
      </o-tab-item>
      <o-tab-item label="Notices" value="notices" v-if="hasNotices">
        <Notices :bid-package-id="bidPackageId" :public-project-view="publicProjectView" />
      </o-tab-item>
      <o-tab-item label="Contacts" value="contacts" v-if="hasContacts">
        <Contacts :bid-package-id="bidPackageId" :public-project-view="publicProjectView" />
      </o-tab-item>
      <o-tab-item label="Submit Question" value="question" v-if="!publicProjectView && showSubmitQuestion">
        <SubmitQuestion :bid-package-id="bidPackageId" />
      </o-tab-item>
      <o-tab-item label="Submit Bid" value="bid" v-if="!publicProjectView && showSubmitBid">
        <SubmitBid :bid-package-id="bidPackageId" :active="activeTab === 'bid'" @updated="loadBidPackageCompanyFiles" />
      </o-tab-item>
    </o-tabs>
  </div>
</template>

<script setup lang="ts">

import Bidding from "@/pages/Projects/Bidding.vue";
import Contacts from "@/pages/Projects/Contacts.vue";
import Info from "@/pages/Projects/Info.vue";
import Notices from "@/pages/Projects/Notices.vue";
import SubmitQuestion from "@/pages/Projects/SubmitQuestion.vue";
import SubmitBid from "@/pages/Projects/SubmitBid.vue";

import {useRoute, useRouter} from "vue-router";

import {
  NotificationError,
} from "@/internal";
import {computed, onMounted, ref, toRefs, watch} from "vue";
import {storeToRefs} from "pinia";
import {useInviteStore} from "@/stores/InviteStore";
import {usePublicProjectStore} from "@/stores/PublicProjectStore";
import {useBidPackageStore} from "@/stores/BidPackageStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useBidPackageFileStore} from "@/stores/BidPackageFileStore";
import {useBidPackageCompanyFileStore} from "@/stores/BidPackageCompanyFileStore";

const bidPackageStore = useBidPackageStore();
const projectStore = useProjectStore();
const bidPackageFileStore = useBidPackageFileStore();
const bidPackageCompanyFileStore = useBidPackageCompanyFileStore();
const publicProjectStore = usePublicProjectStore();
const inviteStore = useInviteStore();
const route = useRoute();
const router = useRouter();
const activeTab = ref('bidding');
const {bidPackage, isLoading} = storeToRefs(useBidPackageStore());
const {company} = storeToRefs(useCompanyStore());
const {invites} = storeToRefs(useInviteStore());

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
  publicProjectView?: boolean,
}>(), {
  bidPackageId: null,
  publicProjectView: false,
});
const {
  bidPackageId,
  publicProjectView,
} = toRefs(props);

const emit = defineEmits<{
  'projectBidderUpdated': [],
}>();

const biddingTabLabel = computed(() => publicProjectView.value ? 'Plans' : 'Bidding');

const hasContacts = computed(() => bidPackageId.value ? bidPackage.value?.estimators.length || bidPackage.value?.project_contacts.length : project.value?.estimators.length || project.value?.project_contacts.length);

const hasNotices = computed(() => bidPackageId.value ? bidPackage.value?.notices.length : project.value?.notices.length);

const hasInvites = computed(() => !!invites.value?.length);

const projectId = computed(() => parseInt(route.params.projectId as string));

const project = computed(() => publicProjectView.value ? publicProjectStore.project : projectStore.project);

const showSubmitBid = computed(() => project.value?.show_bid_upload);

const showSubmitQuestion = computed(() => company.value?.client.show_rfi);

onMounted(() => {
  loadBidPackage();
  loadBidPackageFiles();
  loadBidPackageCompanyFiles();

  if (project.value?.show_bid_upload) {
    loadInvites();
  }
});

function handleProjectBidderUpdated() {
  emit('projectBidderUpdated');
}

function loadBidPackage() {
  if (!bidPackageId.value) {
    return;
  }

  activeTab.value = "bidding";

  const payload = {
    params: {
      include: [
        'estimators',
        'notices',
        'projectContacts',
      ],
    }
  };

  bidPackageStore.show(bidPackageId.value, projectId.value, payload)
    .catch(error => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    });
}

function loadInvites() {
  const payload = {
    params: {
      include: [
      ],
    }
  };

  if (bidPackageId.value) {
    payload.params.bid_package_id = bidPackageId.value;
  }

  inviteStore.index(projectId.value, payload)
    .catch(error => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    });
}

function loadBidPackageFiles() {
  if (!project.value || bidPackageId.value === null) {
    return;
  }

  const payload = {
    params: {
      include: [
      ],
    }
  };

  bidPackageFileStore.index(projectId.value, bidPackageId.value, payload)
      .catch(error => {
        if (error.errors) {
          NotificationError({}, error);
        } else {
          NotificationError({
            message: error,
          });
        }
      });

}

function loadBidPackageCompanyFiles() {
  if (!project.value || bidPackageId.value === null) {
    return;
  }

  const payload = {
    params: {
      include: [
          'trade',
      ],
      file_tree_format: false,
    },
  };

  bidPackageCompanyFileStore.index(projectId.value, bidPackageId.value, payload)
      .catch(error => {
        if (error.errors) {
          NotificationError({}, error);
        } else {
          NotificationError({
            message: error,
          });
        }
      });

}

watch(bidPackageId, () => {
  loadBidPackage();

  if (project.value?.show_bid_upload) {
    loadInvites();
  }
});

</script>

<style lang="scss" scoped>

#tabs {

}

</style>

<style lang="scss">

#tabs {
  width: 100%;

  .b-tabs {
    height: 100%;
    padding-bottom: 4em;

    .tab-content {
      height: 100%;
      padding-top: 2em;
      padding-left: 1em;

      .tab-item {
        height: 100%;
        padding-bottom: 3em;
      }
    }

    .tabs {
      border: 0;
      white-space: nowrap !important;

      &.is-boxed {
        div {
          a {
            border-bottom-color: hsl(0, 0%, 86%);
            border-bottom-style: solid;
            border-bottom-width: 1px;
          }

          &.is-active {
            border-bottom: 0 !important;
          }
        }
      }

      .tab-item {
        height: 100%;
      }
    }
  }

}

</style>