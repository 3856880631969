import { z } from "zod";

import {BidPackageSchema} from "@/models/BidPackage";
import {
    utcToLocal,
} from "@/internal";
import {UserSchema} from "@/models/User";
import {ProjectContactSchema} from "@/models/ProjectContact";
import {ProjectBidderSchema} from "@/models/ProjectBidder";
import {NoticeSchema} from "@/models/Notice";
import {OPRFileSchema} from "@/models/OPRFile";
import {ClientSchema} from "@/models/Client";
import formatRFQDateTime from "@/use/formatRFQDateTime";

const dateRegex = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
const timeRegex = new RegExp("^[0-2][0-9](:[0-5][0-9]){2}$");

export const ProjectSchema = z.object({
    id: z.coerce.number(),
    name: z.string().optional(),
    client_id: z.coerce.number().optional(),
    business_unit_id: z.string().optional(),
    number: z.string().optional(),
    address: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    zip: z.string().nullable(),
    location: z.string().nullable(),
    confirmed: z.number().nullable().default(3),
    scope: z.string().nullable(),
    exclusions: z.string().nullable(),
    other_info: z.string().nullable(),
    has_bid_packages: z.coerce.boolean().nullable(),
    invited: z.coerce.boolean().nullable(),
    open_invitation: z.coerce.boolean().nullable(),
    limit_opr_to_awarded: z.coerce.boolean().nullable(),
    show_bid_upload: z.boolean().optional().nullable(),
    is_bidding: z.coerce.number().default(0),
    is_bid_uploaded: z.coerce.boolean().default(false),

    sub_bid_date: z.string().regex(dateRegex).transform((val:string) => formatRFQDateTime(val, '')).nullable(),
    sub_bid_time: z.string().regex(timeRegex).transform((val:string) => formatRFQDateTime('', val)).nullable(),
    sub_bid_due: z.string().optional().nullable(),

    site_meeting_date: z.string().regex(dateRegex).transform((val:string) => formatRFQDateTime(val, '')).nullable(),
    site_meeting_time: z.string().regex(timeRegex).transform((val:string) => formatRFQDateTime('', val)).nullable(),
    site_meeting_at: z.string().optional().nullable(),

    created_at: z.string().transform((val:string) => utcToLocal(val)).nullable(),

    bidders: z.array(ProjectBidderSchema).optional().default([]),
    bid_packages: z.array(BidPackageSchema).optional().default([]),
    client: ClientSchema.optional().default(ClientSchema.parse({})),
    estimators: z.array(UserSchema).optional().default([]),
    notices: z.array(NoticeSchema).optional().default([]),
    project_contacts: z.array(ProjectContactSchema).optional().default([]),
});


export type Project = z.infer<typeof ProjectSchema>;