<template>
  <div id="submit-question-tab" class="tab is-full-height">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="label">Request for Information</div>

    <div>
      <o-field label="RFI for Trade"
               addons-class="addons-class"
               :variant="error('trade_id') ? 'danger' : ''"
               :message="error('trade_id')"
               v-if="bidders?.length"
      >
        <o-dropdown v-model="trade"
                    aria-role="list"
                    menu-class="menu-class"
        >
          <template #trigger="{ active }">
            <div class="button is-secondary is-outlined">
              <span>{{ (trade?.full_label) || '--- Select ---'}}</span>
              <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
            </div>
          </template>

          <o-dropdown-item :value="null" v-if="trade !== null">--- Select ---</o-dropdown-item>
          <o-dropdown-item :value="bidder.trade" v-for="bidder in bidders">{{ bidder?.trade?.full_label }}</o-dropdown-item>
        </o-dropdown>
      </o-field>

      <o-field label="Subject*"
               :variant="error('subject') ? 'danger' : ''"
               :message="error('subject')"
      >
        <o-input v-model="subject" required maxlength="150"></o-input>
      </o-field>

      <o-field label="Body*"
               :variant="error('body') ? 'danger' : ''"
               :message="error('body')"
      >
        <o-input v-model="body" type="textarea" required></o-input>
      </o-field>
    </div>

    <div class="has-text-right has-margin-top-large">
      <o-button class="is-primary has-margin-right" @click="handleCancel">Cancel</o-button>
      <o-button class="is-primary" @click="handleSend">Send RFI</o-button>
    </div>

  </div>
</template>

<script setup lang="ts">

import {
  NotificationError,
  NotificationSuccess,
} from "@/internal";
import {computed, ref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import type {ProjectBidder} from "@/models/ProjectBidder";
import {useProjectRFIStore} from "@/stores/ProjectRFIStore";
import type {Trade} from "@/models/Trade";
import type {ProjectRFI} from "@/models/ProjectRFI";
import {useBidPackageProjectBidderStore} from "@/stores/BidPackageProjectBidderStore";
import {useProjectStore} from "@/stores/ProjectStore";
import {useCompanyStore} from "@/stores/CompanyStore";

const { bidPackageProjectBidders } = storeToRefs(useBidPackageProjectBidderStore());
const { project } = storeToRefs(useProjectStore());
const { isLoading } = storeToRefs(useProjectRFIStore());
const { company } = storeToRefs(useCompanyStore());
const projectRFIStore = useProjectRFIStore();
const errors = ref(<Record<any, any>>[]);
const body = ref('');
const trade = ref<Trade | null>(null);
const subject = ref('');

const props = withDefaults(defineProps<{
  bidPackageId: number | null,
}>(), {
  bidPackageId: null,
});
const {
  bidPackageId,
} = toRefs(props);

const bidders = computed<ProjectBidder[]>(() => {
  const trades: number[] = [];
  if (isOpenProjectAndUninvited.value && !bidPackageProjectBidders.value?.length) {
    return company.value ? company.value.trades.map((trade: Trade) => {
      return {
        id: trade.id,
        trade: trade,
      };
    }) : [];
  }
  else {
    return bidPackageProjectBidders.value?.filter((bidder: ProjectBidder) => {
      if ((bidder.trade && trades.includes(bidder.trade.id)) || !bidder.trade) {
        return false;
      } else {
        trades.push(bidder.trade.id);
        return true;
      }
    });
  }
});

const isOpenProjectAndUninvited = computed(() => project.value?.open_invitation && !project.value.invited);

function clearErrors() {
  errors.value = [];
}

function error(key: string) {
  return errors.value?.find((error: any) => error.hasOwnProperty('key') && error.key === key)?.detail || '';
}

function handleCancel() {
  subject.value = '';
  body.value = '';
  trade.value = null;
}

function handleSend() {
  if (!project.value) {
    return;
  }

  clearErrors();

  const payload: any = {
    subject: subject.value,
    body: body.value,
    trade_id: trade.value ? trade.value.id : null,
  };

  if (bidPackageId.value) {
    payload.bid_package_id = bidPackageId.value;
  }

  projectRFIStore.store(project.value.id, payload).then((response: ProjectRFI) => {
    NotificationSuccess({
      message: `RFI Sent`,
    });

    handleCancel();
  }).catch((error: any) => {
    if (error.hasOwnProperty('errors')) {
      errors.value = error.errors;
    }
    else {
      NotificationError({
        message: error,
      });
    }
  });
}

</script>

<style lang="scss" scoped>

#submit-question-tab {

}

.menu-class {
  max-height: 300px;
}

</style>

<style lang="scss">

#submit-question-tab {
  .addons-class {
    display: block !important;
  }

  .dropdown {
    a {
      &.dropdown-item {
        justify-content: left;

        &.is-active {
          color: $white !important;
        }
      }
    }
  }
}

</style>