import type {APIResponse, ObjectWithMeta} from '@/api/Api';
import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import type {ProjectBidder} from "@/models/ProjectBidder";
import {ProjectBidderSchema} from "@/models/ProjectBidder";

export default class BidPackageProjectBidderAPI extends Api {

  index(projectId: number, bidPackageId: number, params: any): Promise<ObjectWithMeta<ProjectBidder>> {
    return this.get({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/project-bidders`,
      queryParams: params
    })
        .then((res) => this.processResponse(res))
        .then(res => {
          return {
            links: res.links,
            meta: res.meta,
            data: deserialize<ProjectBidder>(res, ProjectBidderSchema) as ProjectBidder[],
          };
        });
  }

  show(id: number, projectId: number, bidPackageId: number, params: any): Promise<ProjectBidder> {
    return this.get({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/project-bidders/${id}`,
      queryParams: params
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<ProjectBidder>(res, ProjectBidderSchema) as ProjectBidder);
  }

  store(projectId: number, bidPackageId: number, data: any) {
    return this.post({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/project-bidders`,
      headers: [],
      body: JSON.stringify(data),
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<ProjectBidder>(res, ProjectBidderSchema) as ProjectBidder[]);
  }

  update(id: number, projectId: number, bidPackageId: number, data: any) {
    return this.put({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/project-bidders/${id}`,
      headers: [],
      body: JSON.stringify(data),
    })
    .then((res) => this.processResponse(res))
    .then(res => deserialize<ProjectBidder>(res, ProjectBidderSchema) as ProjectBidder);
  }

  updateUnanswered(projectId: number, data: any) {
    return this.post({
      url: `projects/${projectId}/bid-packages/project-bidders`,
      headers: [],
      body: JSON.stringify(data),
    })
    .then((res) => this.processResponse(res))
    .then(res => []);
  }
};
