import {Api} from '@/api/Api';
import {deserialize} from './Deserializer';
import {type OPRFile, OPRFileSchema} from "@/models/OPRFile";

export default class BidPackageFileAPI extends Api {

  index(projectId: number, bidPackageId: number, params: any): Promise<OPRFile[]> {
    return this.get({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/gc-files`,
      queryParams: params
    })
    .then((res) => this.processResponse(res));
  }

  zip(projectId: number, bidPackageId: number, filename: string, params: any): Promise<any> {
    return this.get({
      url: `projects/${projectId}/bid-packages/${bidPackageId}/gc-files/zip`,
      queryParams: params
    })
    .then(res => res.blob())
    .then((response) => {
      const file = new Blob(
          [response],
          {type: 'application/zip'},
      );
      const link = document.createElement('a');
      link.href =  URL.createObjectURL(file);
      link.download = `${filename}.zip`;
      document.body.append(link);
      link.click();
      link.remove();
    });
  }
};
