<template>
  <div class="app">

    <template v-if="authenticated && !publicProjectRoute">
      <NavMenuPanel />
      <NavigationGlobal class="is-hidden-mobile" />
      <NavigationContextual class="is-hidden-mobile" />
      <main id="main">
        <router-view :key="route.path" />
      </main>
    </template>
    <template v-else-if="publicProjectRoute">
      <OpenNavigationGlobal class="is-hidden-mobile" />
      <div id="main" style="flex: 1;">
        <Header />
        <router-view :key="route.path" />
      </div>
    </template>
    <template v-else>
      <div style="flex: 1;">
        <Header />
        <router-view :key="route.path" />
      </div>
    </template>

    <div class="app-footer is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
      <a class="has-margin-right-large" href="https://pipelinesuite.com/" target="_blank"><img src="https://assets.p-suite.io/powered_by_preconSuiteLogo.svg" width="200"></a>
      <a class="has-margin-right-large" href="https://pipelinesuite.com/privacy-policy" target="_blank">Privacy</a>
      <a class="has-margin-right-large is-link" href="https://pipelinesuite.com/terms-of-service" target="_blank">Terms</a>
      <a class="has-margin-right-large is-link" href="https://pipelinesuite.com/contact/" target="_blank">Contact Us</a>
    </div>
  </div>
</template>

<script setup lang="ts">

  import NavMenuPanel from './components/NavMenuPanel.vue'
  import NavigationGlobal from './components/NavigationGlobal.vue'
  import NavigationContextual from './components/NavigationContextual.vue'

  import {ref, computed, watch} from "vue";
  import { useRoute } from "vue-router";

  import Auth from "@/auth";
  import Header from "@/components/Header.vue";
  import OpenNavigationGlobal from "@/components/PublicNavigationGlobal.vue";

  const route = useRoute();

  const authenticated = computed(() => {
    return Auth.isAuthenticated();
  });

  const publicProjectRoute = computed(() => route && route.name?.startsWith('open-projects/'));

</script>

<style lang="scss" scoped>

.app {
  display: flex;
  width: 100%;
  height: 100%;

  #main  {
    padding-bottom: 3em;
  }

  .app-footer {
    margin-top: 1em;
    text-align: right;

    background: $grey-lightest;
    border-top: 1px solid $grey-lighter;
    padding: 15px 45px 15px 323px;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 100;
  }
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);

}

@media (max-width: 768px) {
  .app {
    .app-footer {
      padding-left: 45px;
    }
  }
}
</style>
