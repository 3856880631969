<template>
  <div></div>
</template>

<script setup lang="ts">

  import { onBeforeMount } from "vue";

  import Auth from "@/auth";
  import {useCompanyStore} from "@/stores/CompanyStore";

  const companyStore = useCompanyStore();

  onBeforeMount(() => {
    Auth.getToken()
        .then(Auth.setTokens)
        .then(() => companyStore.getCompany({
          params: {
            include: [
              'client',
              'primaryContact',
              'trades',
            ],
          }
        }))
        .then(() => {
          const redirectPath = window.sessionStorage.getItem('redirectPath') || '/';
          localStorage.removeItem('redirectPath');
          location.replace(redirectPath);
        })
        .catch(err => {
          console.error(err);
        });
  });

</script>

<style lang="scss" scoped>

</style>
