<template>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <IndexTable
        :action="projectsStore.index"
        :columns="defaultTableColumns"
        :default-items-per-page="50"
        :bid-status="statusFilter"
        default-status-filter="active"
        :display-status-filter="false"
        :includes="include"
        :items="projects"
        :params="params"
        paginated
        :pagination-position="paginationPosition"
        :paginator="paginator"
        resource="project"
        title="Projects"
        @click="handleShow"
        @response="handleResponse"
    />
</template>

<script setup lang="ts">

  import {ref, computed, onMounted, watch, toRefs, onBeforeMount} from "vue";
  import type {Ref} from "vue";
  import {useRouter} from "vue-router";

  import {
    findRouteByName,
  } from "@/internal";
  import type {APIResponse} from '@/api/Api';

  import IndexTable, {type PaginationPositionTypes, type PaginatorType} from "@/components/IndexTable.vue";
  import {storeToRefs} from "pinia";
  import {useProjectsStore} from "@/stores/ProjectsStore";

  const {projects, projectsLinks, projectsMeta, isLoading} = storeToRefs(useProjectsStore());
  const projectsStore = useProjectsStore();
  const router = useRouter();
  const include = <any>[];
  const defaultTableColumns = [
    {
      field: 'id',
      label: 'ID',
      width: 40,
      numeric: true,
    },
    {
      field: 'invited',
      label: 'Open/Invited',
      component: 'ProjectInvitedColumn',
    },
    {
      field: 'name',
      label: 'Name',
      component: 'NameColumn',
      sortable: true,
    },
    {
      field: 'city',
      label: 'City',
      sortable: true,
    },
    {
      field: 'location',
      label: 'Location',
      sortable: true,
    },
    {
      field: 'sub_bid_due',
      label: 'Bid Due',
      sortable: true,
    },
    {
      field: 'confirmed',
      label: 'Response',
      component: 'ResponseConfirmedColumn',
    },
  ];

  let paginator: Ref<PaginatorType> = ref<PaginatorType>({
    current_page: 1,
    per_page: 10,
  });

  const props = withDefaults(defineProps<{
    statusFilter?: 'current' | 'past' | null,
  }>(), {
    statusFilter: 'current',
  });
  const {
    statusFilter,
  } = toRefs(props);

  const params = ref({
    sort: (statusFilter.value === 'past' ? '-' : '') + 'sub_bid_due',
  });

  const projectStatus = computed<string>(() => {
    return statusFilter.value === 'past' ? '/past' : '';
  });
  const paginationPosition = computed<PaginationPositionTypes>(() => {
    return "both";
  });

  function handleResponse(response: APIResponse) {
    if (projectsMeta.value?.pagination) {
      paginator.value = projectsMeta.value.pagination;
    }
    else {
      paginator.value = {
        current_page: 1,
        total: projects.value?.length || 0,
      };
    }
  }

  function handleShow(item: any) {
    const route = findRouteByName(`projects${projectStatus.value}/show`);

    route.params = {
      ...route.params,
      projectId: item.id,
    };

    router.push(route);
  }

</script>

<style scoped>

</style>